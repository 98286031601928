import { DatePicker } from "antd";
import styles from "../styles/KpisUI.module.css";
import cx from "classnames";
const DateFilters = ({active,handleActive}) => {

  const filters = [
    { key: "1", label: "HOY" },
    { key: "2", label: "AYER" },
    { key: "3", label: "ÚLTIMOS 7 DÍAS" },
    { key: "4", label: "ÚLTIMOS 30 DÍAS" },
    { key: "5", label: "MES PASADO" },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {filters.map((item) => (
          <span
            className={cx(styles.tab, active === item.key && styles.activeTab)}
            onClick={() => handleActive(item.key)}
          >
            {item.label}
          </span>
        ))}
      </div>
      <div className={styles.pickers}>
        <DatePicker.MonthPicker style={{ width: "100px" }} />
        <DatePicker.RangePicker style={{ width: "225px" }} />
      </div>
    </div>
  );
};

export default DateFilters;
