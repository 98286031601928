import React from 'react'
import KpisUI from './components/KpisUI'

const Main = () => {
  return (
    <div>
        <KpisUI />
    </div>
  )
}

export default Main