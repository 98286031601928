import React, { useState } from "react";
import styles from "../styles/KpisUI.module.css";
import HeaderList from "../../../components/HeaderList";
import DateFilters from "./DateFilters";
import NavItems from "./NavItems";
import GridCard from "./Summary";
import TopProducts from "./TopProducts";
import OrdersArea from "./OrdersArea";

const KpisUI = () => {
  const [activeCard, handleActiveCard] = useState(1);
  const [activeDate, handleActiveDate] = useState("1");

  return (
    <div className={styles.view}>
      <HeaderList title="KPI's" />
      <div className={styles.layout}>
        <div>
          <DateFilters active={activeDate} handleActive={handleActiveDate} />
          <div className={styles.content}>
          <GridCard /> 
         {/*  <TopProducts  />
          <OrdersArea /> */}
          </div>
        </div>
        <NavItems activeCard={activeCard} handleActiveCard={handleActiveCard} />
      </div>
    </div>
  );
};

export default KpisUI;
